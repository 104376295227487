/* bootstrap 3 css autocomplete  */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #428bca;
    outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.open > .dropdown-menu {
    display: block;
}
.open > a {
    outline: 0;
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-menu-left {
    right: auto;
    left: 0;
}

/* cookie bar */
#cookie-bar {background:#111111; height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:6px 0 3px 0;opacity:0.9}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#007700;}
#cookie-bar .cb-enable:hover {background:#009900;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}

@media screen and (max-width: 480px) {
    #cookie-bar {text-align:left; padding-left: 8px;}
}

/* grid */
.grid table
{
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    color: #555555;
    font-size: 1em;
    width: 100%;
}

.grid td, .grid th
{
    border: 1px solid #D4E0EE;
    padding: 3px 7px 2px 7px;
}
.grid th
{
    background-color: #E6EDF5;
    vertical-align: top;
}

.grid th a {
    color: #4F76A3;
    text-decoration: none;
}

.grid th a.grid-reset {
    margin-left: 5px;
    font-weight: normal;
}

.grid tr.even
{
    background-color: #FCFDFE;
}

.grid tr.odd {
    background-color: #F7F9FC;

}

.grid_header, .grid_footer {
    margin: 5px 0;
}

.grid th div {
    height: 10px;
    width: 20px;
    float: right;
    padding-top: 4px;
}

.grid th div.sort_up {
    background: transparent url("data:image/gif;base64,R0lGODlhFwAKAIABAJCQkO/v7yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAEALAAAAAAXAAoAAAIajI+py+0GwGsxTmVDvlqe/YCQ52wmyaXqUQAAOw==") no-repeat bottom left;
}

.grid th div.sort_down {
    background: transparent url("data:image/gif;base64,R0lGODlhFwAKAHAAACH5BAEAAAIALAAAAAAXAAoAgQAAAJCQkAAAAAAAAAIalI+py60RDpTRiZmwvdXozXkdKH6keKZqUwAAOw==") no-repeat bottom left;
}

.grid .grid_boolean_true {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACl0lEQVR42q2T60uTYRiH/Tv2bnttAwlkRCGChFD7FCQSm2ZDMQ/L0nRnj7TNGDbTooychzFSSssstdqc8zB1anNrSpm47FVCzH3pQLVhdLBfzztoJlifvOEHz4fnuu7nGBe311XgOyLMnTmsz/akMBljB8OSEVFY4kpkJM5Efbp9v/C/cJ43VSrzJId0HhluBy3oW+mKpnOpGSWuExD30iFxDy3dFSZdpZkTSZHr80Y41/phe3UDpvnKaNixY60PjbNVOGTjRZJtvJ2SHE+KINOdtMHC7MSaQBkq/CXQzJ6DjqScpNp3HvY3D3B5ugIiC3dDdJMriAlk7iSDajwr2pmFWVDlPQPFTCEU0wVQTxfCvT4Ig1cJB5Hk9hxDwjWuISbIGBExncFmWINNqPAVQ/lUTsB8KKdIPPmYeOsCW6HIOtpeNMI234j4ei4TExy3J2w+Wr2L2oAGWm8RWckAlj4uQDVZiPH1oSj8c+sH2p5fgWGyGH3BTvCN1GZMIH5Ib/avdMPoV6HWr8Xnb5+i0Iev72KwZa4ealc29O6z6A92gF/zt6CHZm4tNKF98Sp0U3KYfdWIfP8Shbd+bcHy7BLKnFnQEEFLoA7tXjPoKmp7C6l3+Ab5QBrsq/dRPSmH2n0adTPlWH6/iLa5BpQOnoTCcQo6Zw7sr7uRbj0KupLaPsRkK09wgFyN2aPBY+YeKkfzoB3OgWpIBqWDDQtn48lyF4xDxeCrORu0mhLseAuJTVxpfAMVMbnL4CCS1oAZ+tEiXBiWo5VswU5gvbMIvFJOhMC7v8Z9DVwpbaJCkg4x2v1m9L60onfBCovXhLSWVPAVnBCt+gf8p+iLXCFtoPR0DcXwtZwwX8UJk44MiZ4upYR7/nt/A+w9sdKFchsrAAAAAElFTkSuQmCC") no-repeat bottom left;
    display: inline-block;
    text-indent: 16px;
    width: 16px;
    overflow: hidden;
}

.grid .grid_boolean_false {
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACSElEQVR42q2Ty0vUURTHv/5SJ1SmUaGckUYdG5FyVdAicGMkFAXBtEl8gJseIP0FEVEtbNNiFm0iMMUgGKhVIUj0oqKFQ40N2TwwdewxOik6zcy953tbmD/HxFV9d/dwz+f7PXAO8I8q+bugb1xspjFdhuwlWUcSJL+SvEfhaPXgcHxbQOH6hYChCa6WlLvLm/eh1OkEAKjlJWSjUVjZpXlSBvbcehDaAshfOx8w5FB+t7eyosUPSU2DqWkAgOX2wvI0YPljFLmpD6sU6fPefhSyAbmr53wkXxTqvJ7KlhYUXo0BACou3wEArFzpB2hQ3t6JpcgkVqITKSHb/XefJCwAENHdWcux0WwMDI09GjUh2iA7/hjO1v0wVbUerVQ3AFgAoLXucfj9kNkkDA0oBkZzE4BKIAUin4hjV2sblFI9xYD6MpcLeiYJo2k7rksKhKi1ei4WR5mrBlrp+g2A0jBi7MZ1RzUV2RhB/YEIASG0VihKoOby6UVY7gY7qiji18txrDy8b7tTEaV7G5FNp6GVnitOMPwz8h47PI32Z1GEs/8Sqk6fBRUhmhAhHI0+fA+/g9Z62AYopUYKC6lUZjKCio7jNmQxeBMLwUGIrL1dJ07iRySMzMznlNZ6ZNMiTXZ3BCgy5DpwuLK6tQ25ZAK5WBzGGDiafHA0+ZCOhPHl9dgqyb6jTz+FtqzyxJkjASGDO2s87tq2g3C4qmEMkMss4tvEW2RmY/MkB449mwpte0xvTh1qJtlFYS8pm4+JHO18Hovjf+o3Xg+XX4ZLBPIAAAAASUVORK5CYII=") no-repeat bottom left;
    display: inline-block;
    text-indent: 16px;
    width: 16px;
    overflow: hidden;
}

.grid .align-left {
    text-align: left;
}

.grid .align-center {
    text-align: center;
}

.grid .align-right {
    text-align: right;
}

.grid .grid-filter-operator select{
    width: 70px;

}

.grid .grid-filter-input-query input, .grid .grid-filter-select-query select{
    width: 50px;
}

.grid .grid-filter-input-query-to, .grid .grid-filter-select-query-to{
    margin-left: 77px;
    display: block;
}

.grid-search {
    border: 1px solid #D4E0EE;
    padding: 10px;
}

.grid-search label{
    width: 80px;
    display: inline-block;
    text-align: right;
}

.grid-search select, .grid-search .grid-filter-input-query input {
    width: 150px;
}

/* Leaflet map  */

/* required styles */

.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
}
.leaflet-container {
    overflow: hidden;
    -ms-touch-action: none;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block;
}
/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
    max-width: none !important;
}
/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
    max-width: 15000px !important;
}
.leaflet-tile {
    filter: inherit;
    visibility: hidden;
}
.leaflet-tile-loaded {
    visibility: inherit;
}
.leaflet-zoom-box {
    width: 0;
    height: 0;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
    -moz-user-select: none;
}

.leaflet-tile-pane    { z-index: 2; }
.leaflet-objects-pane { z-index: 3; }
.leaflet-overlay-pane { z-index: 4; }
.leaflet-shadow-pane  { z-index: 5; }
.leaflet-marker-pane  { z-index: 6; }
.leaflet-popup-pane   { z-index: 7; }

.leaflet-vml-shape {
    width: 1px;
    height: 1px;
}
.lvml {
    behavior: url(#default#VML);
    display: inline-block;
    position: absolute;
}


/* control positioning */

.leaflet-control {
    position: relative;
    z-index: 7;
    pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
}
.leaflet-top {
    top: 0;
}
.leaflet-right {
    right: 0;
}
.leaflet-bottom {
    bottom: 0;
}
.leaflet-left {
    left: 0;
}
.leaflet-control {
    float: left;
    clear: both;
}
.leaflet-right .leaflet-control {
    float: right;
}
.leaflet-top .leaflet-control {
    margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
    margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
    margin-left: 10px;
}
.leaflet-right .leaflet-control {
    margin-right: 10px;
}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    opacity: 1;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
    -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
    -o-transition:      -o-transform 0.25s cubic-bezier(0,0,0.25,1);
    transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
    visibility: hidden;
}


/* cursors */

.leaflet-clickable {
    cursor: pointer;
}
.leaflet-container {
    cursor: -webkit-grab;
    cursor:    -moz-grab;
}
.leaflet-popup-pane,
.leaflet-control {
    cursor: auto;
}
.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor:    -moz-grabbing;
}


/* visual tweaks */

.leaflet-container {
    background: #ddd;
    outline: 0;
}
.leaflet-container a {
    color: #0078A8;
}
.leaflet-container a.leaflet-active {
    outline: 2px solid orange;
}
.leaflet-zoom-box {
    border: 2px dotted #38f;
    background: rgba(255,255,255,0.5);
}


/* general typography */
.leaflet-container {
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}


/* general toolbar styles */

.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
}
.leaflet-bar a:hover {
    background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb;
}

.leaflet-touch .leaflet-bar a {
    width: 30px;
    height: 30px;
    line-height: 30px;
}


/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font: bold 18px 'Lucida Console', Monaco, monospace;
    text-indent: 1px;
}
.leaflet-control-zoom-out {
    font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
    font-size: 22px;
}
.leaflet-touch .leaflet-control-zoom-out {
    font-size: 24px;
}


/* layers control */

.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(0,0,0,0.4);
    background: #fff;
    border-radius: 5px;
}
.leaflet-control-layers-toggle {
    background-image: url('https://www.hachette-vins.com/css/images/layers.png');
    width: 36px;
    height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
    background-image: url('https://www.hachette-vins.com/css/images/layers-2x.png');
    background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative;
}
.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: #333;
    background: #fff;
}
.leaflet-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px;
}
.leaflet-control-layers label {
    display: block;
}
.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid #ddd;
    margin: 5px -10px 5px -6px;
}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: #333;
}
.leaflet-control-attribution a {
    text-decoration: none;
}
.leaflet-control-attribution a:hover {
    text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
    font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
    margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
}
.leaflet-control-scale-line {
    border: 2px solid #777;
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    background: #fff;
    background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid #777;
    border-bottom: none;
    margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
}


/* popup */

.leaflet-popup {
    position: absolute;
    text-align: center;
}
.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left;
    border-radius: 12px;
}
.leaflet-popup-content {
    margin: 13px 19px;
    line-height: 1.4;
}
.leaflet-popup-content p {
    margin: 18px 0;
}
.leaflet-popup-tip-container {
    margin: 0 auto;
    width: 40px;
    height: 20px;
    position: relative;
    overflow: hidden;
}
.leaflet-popup-tip {
    width: 17px;
    height: 17px;
    padding: 1px;

    margin: -10px auto 0;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: white;

    box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}
.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
    color: #999;
}
.leaflet-popup-scrolled {
    overflow: auto;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
    zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;

    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
    margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid #999;
}


/* div icon */

.leaflet-div-icon {
    background: #fff;
    border: 1px solid #666;
}

#map { height: 220px; }


.leaflet-control-easyPrint a {
    background:#fff url('https://www.hachette-vins.com/css/images/print.png') no-repeat 5px;
    background-size:16px 16px;
    display: block;
}



@media print {

    html {padding: 0px!important;}
    .leaflet-control-easyPrint-button{display: none!important;}

}

/* Leaflet map  */


/*  GENERIC CSS */
.black, .black a{color: black}
.white, .white a{color: white}
.red, .red a{color:#d33b38;}
.grey, .grey a{color:#666666}
.pink, .pink a{color:#F77496;}

.bold{font-weight: bold}

.pdg{padding:10px}

.mrgr{margin-right:10px}
.mrgR{margin-right:20px}

.small{font-size: x-small}

/*.pub_ad{text-align:center;position:relative;width:auto;margin:0 auto;overflow:hidden;z-index:1;clear:both}*/
.pub_ad{text-align:center; position:relative; width:auto;margin:0 auto;overflow:hidden;z-index:1;}
.pubPositionLeft {float:left; padding-left: 30px;}
.pubPositionRight {float:right; padding-right: 30px;}

/* OVERRIDE */
.desktop-fixed-header{height:70px;padding:15px 0px 10px;z-index:900;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6), 0 5px 9px 0 rgba(0, 0, 0, 0.3)}
.desktop-fixed-header .menu a:hover{color:#d33b38;}
/*.desktop-fixed-header div.logo{margin-top:6px;}*/

.breadcrumbs li{list-style-type: none;float:left;padding-right:5px;}

.home-slider .flex-control-nav{bottom: 150px;}

.region-slider .slides li{min-height:105px}

.tab-area .favorite-area .right-panel .header .title a {font-size: 1em;}

.favorite-area .right-panel .header .title a {font-size: 16px}

.selection-area .legend .items .item {margin-right: 50px;}

.media{float:left;margin:5px 10px 10px 5px}

.search-area .search-criteria{top:-10px}
.search-area .search-criteria .items .item .search-popup a:hover{color:#d33b38}
.search-area .search-criteria .items .item .search-popup{z-index: 100}

#scrollUp {background-image: url("https://www.hachette-vins.com/img/top.png");bottom: 20px;right: 20px;width: 38px;height: 38px;text-indent: -5000px;overflow: hidden;}

.loading {background:url('https://www.hachette-vins.com/img/loading.gif') no-repeat top left;width:16px;height:11px;display:inline-block;}

.twitter-typeahead{top:-8px}

/*.news-area .items .item:nth-child(2n+1){clear:none;}*/

.fiche-2-area .tags a.white {background: #bcb34e;}
.fiche-2-area .tags a.pink {background: #ec7658;}
.fiche-2-area .tags a.red {background: #981a03;}

.fiche-2-area .top .image img, .product-area .top .info .meta .phone {width:auto}
.fiche-2-area .top {margin-bottom: 0}
.fiche-2-area .top .image .overlay {margin:0}

.fiche-2-area .bottom .title{font-size: 1.4em}

.fiche-2-area .top .location .icon{margin:0}

.main-header .menu-bar .main-menu-toggle {z-index: 100}

.tab-area .tab-content ul {font-size: 10px}

.fiche-region-area .item ul {padding-left:8px}
.fiche-region-area .item ul li:before{left:-4px}
.fiche-region-area .item ul.columns li, .fiche-region-area .item ul li{white-space: normal;padding-left: 4px}

.icon-region-corse {background-position: -209px -399px;}

.link-grey{color:#454545;font-size: 1.4em;font-weight: bold;}
.link-red{color:#d33b38;font-size: 1.4em;font-weight: bold;}

.advertise{margin-top: 20px;}
.advertise .icon{padding-right: 20px}

#habillage{height:0}

.alert {
    padding: 10px 35px 10px 14px;
    color: #c09853;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.alert-heading {
    color: inherit;
}

.alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 18px;
}

.alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert-danger,
.alert-error {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

.alert-info {
    color: #3a87ad;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

@media screen and (max-width: 480px) {
    .search-area .search-criteria{top: 0;}
}
/* OVERRIDE */

.steps ul li a {
    text-decoration: none;
    display: inline-block;
    color: #000000;
    cursor: pointer;
}
.steps ul li a:hover {
    color: #d74a50;
}